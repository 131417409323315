<template>
  <header class="header_box">
    <div class="left">
      <router-link class="title" to="/">
        <img class="logo" src="@/assets/common/logo_text.png" alt="" />
        <!-- <span>Tokex</span> -->
      </router-link>
      <div class="link_box">
        <el-dropdown class="link" placement="bottom" @visible-change="handleBuyCoinsVisibe" v-if="showCtc">
          <div>
            <span class="hover">{{ $t('message.header.buyCoin') }}</span>
            <i class="el-icon-arrow-down down_logo" :class="{ bottom_logo_reverse: buyCoinsReverse }" />
          </div>
          <el-dropdown-menu class="common_page_menu" slot="dropdown">
            <el-dropdown-item>
              <div class="trade_dropdown_box">
                <div class="flex-1">
                  <div class="commom_page_item flex">
                    <router-link class="w-p100" :to="{ name: 'BuyFast' }">
                      <div class="flex align-center p-tb-12">
                        <img class="h-10 w-14 m-r-14" src="@/assets/buyCoinsImg/headbuy-fast.png" alt="" />
                        <div>
                          <div class="font-14 l-h-22 m-b-4 text-white">{{ $t('message.header.quickCoin') }}</div>
                          <div class="font-12 l-h-17 text-c90959C">{{ $t('message.header.oneTrading') }}</div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="commom_page_item">
                    <router-link class="w-p100" :to="{ name: 'BuyCustorm' }">
                      <div class="flex align-center p-tb-12 ">
                        <img class="h-14 w-14 m-r-14" src="@/assets/buyCoinsImg/headbuy_c2c.png" alt="" />
                        <div>
                          <div class="font-14 l-h-22 m-b-4 text-white">{{ $t('message.header.c2c') }}</div>
                          <div class="font-12 l-h-17 text-c90959C">{{ $t('message.header.free') }}</div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <router-link class="link hover" :to="{ name: 'Quotes', params: { type: 'spot' } }">
          <span class="hover">{{ $t('message.header.quote') }}</span>
        </router-link>
        <el-dropdown class="link" placement="bottom" @visible-change="handleTradeVisibe">
          <div>
            <span class="hover">{{ $t('message.home.deal') }}</span>
            <i class="el-icon-arrow-down down_logo" :class="{ bottom_logo_reverse: tradeReverse }" />
          </div>
          <el-dropdown-menu class="common_page_menu" slot="dropdown">
            <el-dropdown-item>
              <div class="trade_dropdown_box">
                <div class="flex-1" v-if="showCtc">
                  <div class="commom_page_item" >
                    <router-link class="w-p100" :to="{ name: 'BuyCustorm' }">{{ $t('message.header.c2cTrad')
                    }}</router-link>
                  </div>
                  <div class="commom_page_item">
                    <router-link class="w-p100" :to="{ name: 'BuyFast' }">{{ $t('message.header.fastTrading')
                    }}</router-link>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="commom_page_item">
                    <router-link class="w-p100" :to="{ name: 'Transaction', params: { tradePair: 'BTC_USDT' } }">{{
                      $t('message.footer.spotDeal') }}</router-link>
                  </div>
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown class="link" placement="bottom" @visible-change="handleDerivativeVisibe">
          <div>
            <span class="hover">{{ $t('message.header.derivatives') }}</span>
            <i class="el-icon-arrow-down down_logo" :class="{ bottom_logo_reverse: derivativeReverse }" />
          </div>
          <el-dropdown-menu class="common_page_menu" slot="dropdown">
            <el-dropdown-item>
              <div class="commom_page_item">
                <router-link class="w-p100" :to="{ name: 'FuturesTrade', params: { tradePair: 'BTCUSDT' } }">{{
                  $t('message.header.uFut') }}</router-link>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="commom_page_item">
                <router-link class="w-p100" :to="{ name: 'DeliveryTrade', params: { tradePair: 'BTCUSD' } }">{{
                  $t('message.quotes.mock') }}</router-link>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown class="link" placement="bottom" @visible-change="handleMoreVisibe">
          <div>
            <span class="hover">{{ $t('message.header.more') }}</span>
            <i class="el-icon-arrow-down down_logo" :class="{ bottom_logo_reverse: moreReverse }" />
          </div>
          <el-dropdown-menu class="common_page_menu" slot="dropdown">
            <el-dropdown-item>
              <div class="trade_dropdown_box">
                <div class="common_more_page">
                  <div class="commom_page_item" v-for="item in productPageList" :key="item.name">
                    <router-link class="w-p100" to="">{{ item.name }}</router-link>
                  </div>
                </div>
                <div class="common_more_page">
                  <div class="commom_page_item" v-for="item in otherPageList" :key="item.name">
                    <router-link class="w-p100" :to="item.url">{{ item.name }}</router-link>
                  </div>
                </div>
                <div class="common_more_page">
                  <div class="commom_page_item" v-for="item in helpPageList" :key="item.name">
                    <router-link class="w-p100" :to="item.url">{{ item.name }}</router-link>
                  </div>
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <router-link class="link hover" to="">
          <span class="hover">{{ $t('message.header.manage') }}</span>
        </router-link>
        <router-link class="link hover" style="margin-right: 0;" to="">
          <span class="hover">{{ $t('message.header.newbie') }}</span>
        </router-link>
      </div>
    </div>
    <div class="right">
      <div v-if="!isLogin" class="sign_box">
        <router-link class="login" to="/login">{{ $t('message.common.login') }}</router-link>
        <router-link class="register" to="/register">{{ $t('message.common.regis') }}</router-link>
      </div>
      <div v-else class="logged">
        <el-dropdown class="wallet" placement="bottom-end" @command="handleWalletCommand"
          @visible-change="handleWalletVisibe">
          <div>
            <span class="hover">{{ $t('message.header.wallet') }}</span>
            <i class="el-icon-caret-bottom bottom_logo" :class="{ bottom_logo_reverse: walletReverse }" />
          </div>
          <el-dropdown-menu slot="dropdown" class="right_common_dropdown_menu hover_dropdown">
            <el-dropdown-item v-for="(item, index) in walletMenuList" :key="item.command" :command="item.command">
              <div class="nav_order_item" @mouseenter="currentOrder = index" @mouseleave="currentOrder = null">
                <!-- currentOrder === index ? item.icon2 :  -->
                <img class="nav_order_logo" :src="item.icon1" />
                <span>{{ item.name }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown class="order" placement="bottom-end" @command="handleOrderCommand"
          @visible-change="handleOrderVisibe">
          <div>
            <span class="hover">{{ $t('message.header.order') }}</span>
            <i class="el-icon-caret-bottom bottom_logo" :class="{ bottom_logo_reverse: orderReverse }" />
          </div>
          <el-dropdown-menu slot="dropdown" class="right_common_dropdown_menu hover_dropdown">
            <el-dropdown-item v-for="(item, index) in orderMenuList" :key="item.command" :command="item.command">
              <div class="nav_order_item" @mouseenter="currentOrder = index" @mouseleave="currentOrder = null">
                <!-- currentOrder === index ? item.icon2 :  -->
                <img class="nav_order_logo" :src="item.icon1" />
                <span>{{ item.name }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- @command="handleUserCommand" :command="item.command" -->
        <el-dropdown ref="userDropdown" class="margin20" placement="bottom-end">
          <span @mouseenter="selectUser = !selectUser" @mouseleave="selectUser = false">
            <!-- :style="{
                'background-image': selectUser
                  ? 'url(' + require('../../assets/home/user_icon_y.png') + ')'
                  : 'url(' + require('../../assets/home/user_icon.png') + ')',
              }" -->
            <i class="user_icon" />
          </span>
          <el-dropdown-menu slot="dropdown" class="right_common_dropdown_menu hover_dropdown">
            <el-dropdown-item>
              <div class="userinfo" @click="handleUserCommand('user')">
                <img class="user_logo" v-if="user_info.user_image" :src="user_info.user_image" alt="" />
                <img class="user_logo" v-else src="@/assets/home/avatar.png" alt="" />
                <div>
                  <div class="name_text">
                    <span>{{ user_info.nick_name }}</span>
                    <img :src="authStateImg" alt="" />
                    <span class="level" :class="{
                      verified: user_info.idcard_state === 3,
                      verifyFail: user_info.idcard_state === 2,
                    }">{{ authStateText }}</span>
                  </div>
                  <div class="id_text">
                    <span style="margin-right: 8px">ID:{{ user_info.user_id }}</span>
                    <el-button @click.stop="toCopy">
                      <i class="el-icon-copy-document"></i>
                    </el-button>
                  </div>
                </div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item v-for="(item, index) in userCommand" :key="item.command" :command="item.command"
              @click.native="handleUserCommand(item.command)">
              <div class="item_block" @mouseenter="enter(index)" @mouseleave="userSelect = null">
                <!-- userSelect === index ? item.icon1 :  -->
                <img class="item_icon" :src="item.icon2" alt="" />
                <span>{{ item.title }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <router-link class="margin20 station_box" to="/inmail">
          <div>
            <span @mouseenter="selectMessage = !selectMessage" @mouseleave="selectMessage = false">
              <!-- :style="{
                  'background-image': selectMessage
                    ? 'url(' + require('../../assets/home/message_icon_y.png') + ')'
                    : 'url(' + require('../../assets/home/message_icon.png') + ')',
                }" -->
              <i class="message_icon" />
            </span>
            <!-- || unreadCount !== 0 -->
            <span v-if="unreadCount" class="unread_count">{{ unreadCount }}</span>
          </div>
        </router-link>
      </div>
      <div class="operate_box">
        <el-dropdown class="margin20" placement="bottom">
          <span @mouseenter="selectDownload = !selectDownload" @mouseleave="selectDownload = false">
            <!-- :style="{
                'background-image': selectDownload
                  ? 'url(' + require('../../assets/home/download_y.png') + ')'
                  : 'url(' + require('../../assets/common/download.png') + ')',
              }" -->
            <i class="download_logo" />
          </span>
          <el-dropdown-menu slot="dropdown" class="right_common_dropdown_menu hover_dropdown">
            <el-dropdown-item>
              <div class="download_box">
                <span>{{ $t('message.header.download') }}</span>
                <vue-qr class="download_qrcode_img" :text="downloadCodeUrl" :size="98" :margin="2" colorDark="#313237" />
                <router-link to="/download" class="more_btn">{{ $t('message.header.moreClient') }}</router-link>
                <!-- <span>{{$t('message.header.phone')}}</span> -->
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- :hide-on-click="false" -->
        <el-dropdown placement="bottom" @visible-change="visibleEnvironDropdown">
          <div class="language_text">
            <span>{{ currentLanguage }}/{{ currency }}</span>
            <!-- <span class="line"></span>
            <span>{{ currency }}</span> -->
          </div>
          <environ-dropdown ref="environDropdown" marginTop="33px" :languageList="languageList"
            :currencyList="currencyList"></environ-dropdown>
        </el-dropdown>
      </div>
      <div class="drawer_box">
        <div v-if="isLogin" class="flex align-center">
          <span class="cursor-point" @click="handleDrawer('user')"><i class="user_icon m-r-24" /></span>
        </div>
        <div v-else>
          <router-link class="register" to="/register">{{ $t('message.common.regis') }}</router-link>
        </div>
        <span class="cursor-point" @click="handleDrawer('union')"><i class="union_icon"></i></span>
      </div>
      <el-dropdown v-if="isSet" placement="bottom" ref="setDropdown">
        <img class="setting" src="@/assets/common/setting.png" alt="" />
        <el-dropdown-menu slot="dropdown" class="right_common_dropdown_menu hover_dropdown">
          <div class="layout-toggle font-14 text-c848E9C">
            <div>布局</div>
            <div class="layout-wrap flex justify-between m-t-20">
              <div @click="layoutToggle(0)">
                <div class="layout-item flex justify-center align-center"
                  :class="[isStandard == 0 ? 'border-active' : '']">
                  <div class="item-wrap">
                    <div class="line-wrap flex justify-center align-center m-l-31 m-t-32">
                      <div class="line bg-c27B485"></div>
                      <div class="line bg-D4575C m-l-2"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center m-t-6 p-b-19" :class="[isStandard == 0 ? 'text-active' : '']">标准版</div>
              </div>
              <div @click="layoutToggle(1)">
                <div class="layout-item flex justify-center align-center"
                  :class="[isStandard == 1 ? 'border-active' : '']">
                  <div class="item-wrap">
                    <div class="line-wrap flex justify-center align-center m-l-62 m-t-37">
                      <div class="line bg-c27B485"></div>
                      <div class="line bg-D4575C m-l-2"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center m-t-6 p-b-19" :class="[isStandard == 1 ? 'text-active' : '']">专业版</div>
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-drawer ref="drawer" title="" :visible.sync="drawer" :with-header="!!isLogin">
      <div v-if="isLogin">
        <div v-if="drawerType === 'user'">
          <div class="command_item" v-for="(item) in userCommand" :key="item.command"
            @click="handleUserCommand(item.command)">
            <img class="item_icon" :src="item.icon2" alt="" />
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div v-else>
          <div class="command_item" v-for="(item) in walletMenuList" :key="item.command"
            @click="handleWalletCommand(item.command)">
            <img class="item_icon" :src="item.icon1" />
            <span>{{ item.name }}</span>
          </div>
          <div class="command_item" v-for="(item) in orderMenuList" :key="item.command"
            @click="handleOrderCommand(item.command)">
            <img class="item_icon" :src="item.icon1" />
            <span>{{ item.name }}</span>
          </div>
          <div class="common_line"></div>
          <div class="command_item" @click="handleJump('/download')">
            <img class="item_icon" src="@/assets/common/download.png" />
            <span>{{ $t('message.header.down') }}</span>
          </div>
        </div>
      </div>
      <div v-else class="union_logout">
        <div class="login" @click="handleJump('/login')">{{ $t('message.common.login') }}</div>
        <div class="register union_btn" @click="handleJump('/register')">{{ $t('message.common.regis') }}</div>
        <div class="download union_btn" @click="handleJump('/download')">{{ $t('message.header.down') }} TOKEX APP</div>
      </div>
    </el-drawer>
  </header>
</template>
<script>
import { getToken } from '@/utils/auth'
import { mapState } from 'vuex'
import VueQr from 'vue-qr'
import EnvironDropdown from './environDropdown'
export default {
  name: 'HeaderBox',
  components: {
    VueQr,
    EnvironDropdown
  },
  data() {
    return {
      isLogin: getToken(),
      userSelect: null,
      currentOrder: null,
      selectUser: false,
      selectMessage: false,
      selectDownload: false,
      downloadCodeUrl: 'http://47.242.142.16:6225/#/download',
      walletReverse: false,
      orderReverse: false,
      tradeReverse: false,
      derivativeReverse: false,
      moreReverse: false,
      buyCoinsReverse: false,
      authStateImg: '',
      authStateText: '',
      isStandard: 1,
      isSet: ['Transaction', 'FuturesTrade', 'DeliveryTrade'].includes(this.$route.name),
      drawer: false,
      drawerType: ''
    }
  },
  computed: {
    ...mapState({
      user_info: state => state.user.user_info,
      rate_data: state => state.user.rate_data,
      rate_list: state => state.user.rate_list,
      language: state => state.user.language,
      currency: state => state.user.currency,
      currencyList: state => state.user.rate_list,
      languageList: state => state.user.languageList,
      langObj: state => state.user.langObj,
      unreadCount: state => state.station.unreadCount,
      localIsStandard: (state) => state.transation.localIsStandard,
      futStandard: (state) => state.futuresTrade.futStandard,
      deliveryStandard: (state) => state.deliveryTrade.deliveryStandard,
      showCtc: (state) => state.assets.showCtc
    }),
    userCommand() {
      return [
        // {
        //   icon1: require('@/assets/home/asset_h.png'),
        //   icon2: require('@/assets/home/asset_g.png'),
        //   title: this.$t('message.header.overview'),
        //   command: 'assets'
        // },
        {
          icon1: require('@/assets/home/safe_h.png'),
          icon2: require('@/assets/home/safe_g.png'),
          title: this.$t('message.header.safe'),
          command: 'safe'
        },
        {
          icon1: require('@/assets/home/verify_h.png'),
          icon2: require('@/assets/home/verify_g.png'),
          title: this.$t('message.header.auth'),
          command: 'verify'
        },
        {
          icon1: require('@/assets/home/invite_h.png'),
          icon2: require('@/assets/home/invite_g.png'),
          title: this.$t('message.header.invite'),
          command: 'invite'
        },
        {
          // icon1: require('@/assets/home/invite_h.png'),
          icon2: require('@/assets/home/api_key.png'),
          title: this.$t('message.header.api'),
          command: 'api'
        },
        {
          icon1: require('@/assets/home/logout_h.png'),
          icon2: require('@/assets/home/logout_g.png'),
          title: this.$t('message.header.logout'),
          command: 'logout'
        }
      ]
    },
    orderMenuList() {
      if (this.showCtc) {
        return [
          {
            icon1: require('@/assets/home/spot_g.png'),
            icon2: require('@/assets/home/spot_y.png'),
            name: this.$t('message.header.spotOrder'),
            command: 'spotOrder'
          },
          {
            icon1: require('@/assets/home/c2c_g.png'),
            icon2: require('@/assets/home/c2c_y.png'),
            name: this.$t('message.header.c2cOrder'),
            command: 'c2c'
          },
          {
            icon1: require('@/assets/home/deal_g.png'),
            icon2: require('@/assets/home/deal_y.png'),
            name: this.$t('message.header.futOrder'),
            command: 'deal'
          }
        ]
      }
      return [
        {
          icon1: require('@/assets/home/spot_g.png'),
          icon2: require('@/assets/home/spot_y.png'),
          name: this.$t('message.header.spotOrder'),
          command: 'spotOrder'
        },
        {
          icon1: require('@/assets/home/deal_g.png'),
          icon2: require('@/assets/home/deal_y.png'),
          name: this.$t('message.header.futOrder'),
          command: 'deal'
        }
      ]
    },
    walletMenuList() {
      return [
        {
          icon1: require('@/assets/home/overview_g.png'),
          icon2: require('@/assets/home/overview_y.png'),
          name: this.$t('message.header.walletMenuItem1'),
          command: 'overview'
        },
        {
          icon1: require('@/assets/home/spot_account_g.png'),
          icon2: require('@/assets/home/spot_account_y.png'),
          name: this.$t('message.header.walletMenuItem2'),
          command: 'spot'
        },
        {
          icon1: require('@/assets/home/assets_account_g.png'),
          icon2: require('@/assets/home/assets_account_y.png'),
          name: this.$t('message.header.walletMenuItem5'),
          command: 'assets'
        },
        {
          icon1: require('@/assets/home/contract_g.png'),
          icon2: require('@/assets/home/contract_y.png'),
          name: this.$t('message.header.walletMenuItem3'),
          command: 'contract'
        }
        // {
        //   icon1: require('@/assets/home/options_g.png'),
        //   icon2: require('@/assets/home/options_y.png'),
        //   name: this.$t('message.header.walletMenuItem4'),
        //   command: 'options'
        // }
      ]
    },
    productPageList() {
      return [
        {
          name: this.$t('message.header.moreMenuItem1')
        },
        {
          name: this.$t('message.header.moreMenuItem2')
        },
        {
          name: 'NFT'
        },
        {
          name: this.$t('message.header.moreMenuItem3')
        },
        {
          name: 'Tokex Chain'
        },
        {
          name: 'Status'
        }
      ]
    },
    otherPageList() {
      return [
        {
          name: this.$t('message.header.invite'),
          url: '/referral'
        },
        {
          name: this.$t('message.header.moreMenuItem4'),
          url: ''
        },
        {
          name: this.$t('message.header.moreMenuItem5'),
          url: ''
        },
        {
          name: 'Jumpstart',
          url: ''
        }
      ]
    },
    helpPageList() {
      return [
        {
          name: this.$t('message.footer.help'),
          url: '/help'
        }
      ]
    },
    currentLanguage() {
      return this.langObj[this.language]
    }
  },
  async created() {
    await this.$store.dispatch('assets/apiConfigCtc')
    this.$store.commit('user/setLangObj')
    this.isStandard = this.$route.name === 'Transaction' ? this.localIsStandard : this.$route.name === 'FuturesTrade' ? this.futStandard : this.deliveryStandard
    if (this.isLogin) {
      if (!this.rate_data || Object.keys(this.rate_data).length <= 0) {
        this.$store.dispatch('user/getRateCountry')
      }
      if (!this.user_info || Object.keys(this.user_info).length <= 0) {
        await this.$store.dispatch('user/getUserInfo')
      }
      this.$store.dispatch('station/getUnreadCount')
    }
    if (!this.rate_list || this.rate_list.length <= 0) {
      await this.$store.dispatch('user/getRateList')
    }
    this.initAuthData()
    if (!this.currency) {
      // 设置默认汇率
      this.currencyList.forEach((item) => {
        if (item.tocoin === 'USD') {
          this.$store.commit('user/setCurrency', item)
          this.$store.commit('user/setRateCountry', item)
        }
      })
    }
  },
  watch: {
    $route: {
      handler() {
        this.isStandard = this.$route.name === 'Transaction' ? this.localIsStandard : this.$route.name === 'FuturesTrade' ? this.futStandard : this.deliveryStandard
      }
    }
  },
  methods: {
    initAuthData() {
      const state = this.user_info.idcard_state
      this.authStateImg =
        state === 3
          ? require('../../assets/home/yes_approve.png')
          : state === 2
            ? require('../../assets/authenticate/fail_logo.png')
            : state === 1
              ? require('../../assets/authenticate/loading_logo.png')
              : require('../../assets/home/not_approve.png')
      this.authStateText =
        state === 3 ? '已认证' : state === 2 ? '审核失败' : state === 1 ? '审核中' : '未认证'
    },
    enter(idx) {
      this.userSelect = idx
    },
    selectUserInfo() {
      console.log('111')
    },
    handleUserCommand(command) {
      console.log('handleUserCommand', command)
      if (command === 'user') {
        this.$router.push({ path: '/account/security' })
      }
      // if (command === 'assets') this.$router.push({ name: 'Wallet', params: { account: 'main' }})
      if (command === 'safe') this.$router.push({ path: '/account/security' })
      if (command === 'verify') this.$router.push({ path: '/account/kyc/personal' })
      if (command === 'invite') this.$router.push({ path: '/referral' })
      if (command === 'api') this.$router.push({ path: '/apiKey' })
      if (command === 'logout') {
        this.$store.dispatch('user/logout').then(() => {
          // ?redirect=${this.$route.fullPath}
          this.$router.push({ path: `/login` })
        })
      }
      this.$refs.drawer.closeDrawer()
    },
    handleWalletCommand(command) {
      console.log(command)
      if (command === 'overview') {
        this.$router.push({ name: 'Wallet', params: { account: 'overview' }})
      }
      if (command === 'spot') {
        this.$router.push({ name: 'Wallet', params: { account: 'main' }})
        // this.$router.push({ path: '/account/dashboard?currentAccount=spot' })
      }
      if (command === 'contract') {
        this.$router.push({ name: 'Wallet', params: { account: 'futures' }})
        // this.$router.push({ path: '/account/dashboard?currentAccount=contract' })
      }
      if (command === 'assets') {
        this.$router.push({ name: 'Funding', params: { account: 'funding' }})
        // this.$router.push({ path: '/account/dashboard?currentAccount=assets' })
      }
      if (command === 'options') {
        this.$router.push({ name: 'Wallet', params: { account: 'main' }})
        // this.$router.push({ path: '/account/dashboard?currentAccount=options' })
      }
      this.$refs.drawer.closeDrawer()
    },
    handleOrderCommand(command) {
      if (command === 'spotOrder') {
        this.$router.push({ name: 'SpotOrder', params: { orderType: 'openorder' }})
      } else if (command === 'deal') {
        this.$router.push({ name: 'FuturesOrder', params: { orderType: 'openorder' }})
      } else {
        this.$router.push({ name: 'Order' })
      }
      this.$refs.drawer.closeDrawer()
    },
    handleJump(path) {
      this.$router.push(path)
      this.$refs.drawer.closeDrawer()
    },
    visibleEnvironDropdown(visible) {
      if (visible) {
        this.$store.dispatch('user/getRateList')
        this.languageList.forEach(el => {
          if (el.value === this.language) {
            this.$refs.environDropdown.currentLanguage = el.name
          }
        })
        this.currencyList.forEach((el, index) => {
          if (el.tocoin === this.currency) {
            this.$refs.environDropdown.currentCurrency = index
          }
        })
      }
    },
    // 衍生品下拉菜单显示 || 隐藏
    handleBuyCoinsVisibe(visible) {
      this.buyCoinsReverse = visible
    },
    // 交易下拉菜单显示 || 隐藏
    handleTradeVisibe(visible) {
      this.tradeReverse = visible
    },
    // 衍生品下拉菜单显示 || 隐藏
    handleDerivativeVisibe(visible) {
      this.derivativeReverse = visible
    },
    // 更多下拉菜单显示 || 隐藏
    handleMoreVisibe(visible) {
      this.moreReverse = visible
    },
    // 钱包下拉菜单显示 || 隐藏
    handleWalletVisibe(visible) {
      this.walletReverse = visible
    },
    // 订单下拉菜单显示 || 隐藏
    handleOrderVisibe(visible) {
      this.orderReverse = visible
    },
    toCopy() {
      const userId = this.user_info.user_id ? this.user_info.user_id.toString() : ''
      this.$copyText(userId).then((e) => {
        this.$message.success({
          message: this.$t('message.user.copyS'),
          duration: 1000
        })
      }, (e) => {
        console.log('error--- ', e)
      })
    },
    // 标准版、专业版切换
    layoutToggle(index) {
      this.$refs.setDropdown.hide()
      this.isStandard = index
      if (this.$route.name === 'Transaction') {
        this.$store.commit('transation/setIsStandard', index)
      } else if (this.$route.name === 'FuturesTrade') {
        this.$store.commit('futuresTrade/setIsStandard', index)
      } else if (this.$route.name == 'DeliveryTrade') {
        this.$store.commit('deliveryTrade/setIsStandard', index)
      }
    },
    handleDrawer(type) {
      this.drawerType = type
      this.drawer = true
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin common() {
  font-size: 14px;
  font-family: PingFang_Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 0px;
}

@mixin commom_icon($w, $h, $url) {
  display: inline-block;
  width: $w;
  height: $h;
  background: $url center no-repeat;
  background-size: cover;
}

.header_box {
  width: 100%;
  height: 64px;
  background-color: #070707;
  padding: 20px 34px 19px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .hover {
    &:hover {
      color: $basicColor;
    }
  }

  .left {
    display: flex;
    align-items: center;

    .link_box {
      display: flex;
      align-items: center;
      overflow-y: hidden;
      overflow-x: scroll;
      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;

      /* IE 10+ */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .title {
      font-size: 14px;
      font-family: STZhongsong-Regular, STZhongsong;
      font-weight: 400;
      color: $basicColor;
      display: flex;
      align-items: center;
      margin-right: 23px;

      .logo {
        width: 103px;
        height: 29px;
      }
    }

    .link {
      flex: 0 0 auto;
      margin-right: 40px;
      @include common();
    }

    .down_logo {
      margin-left: 6px;
      color: #b7bdc6;
      font-size: 14px;
      transition: all 500ms;
    }
  }

  .bottom_logo_reverse {
    transform: rotateZ(180deg);
  }

  .right {
    @include common();
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 20px;

    .login {
      // padding: 6px 20px 6px 0;
      margin-right: 20px;
    }

    .register {
      margin-right: 20px;
      padding: 2px 10px;
      line-height: 22px;
      background-color: $basicColor;
      border-radius: 4px;
      color: #181a20;
    }

    .logged {
      display: flex;
      align-items: center;
    }

    .margin20 {
      margin-right: 20px;
    }

    .station_box {
      position: relative;

      .unread_count {
        display: inline-block;
        position: absolute;
        top: -7px;
        right: -8px;
        color: #fff;
        font-size: 12px;
        background-color: $basicErrorColor;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
      }
    }

    .wallet {
      @include common();
    }

    .order {
      @include common();
    }

    .bottom_logo {
      margin-right: 20px;
      margin-left: 9px;
      color: #B7BDC6;
      transition: all 500ms;
    }

    .user_icon {
      @include commom_icon(20px, 20px, url("@/assets/home/user_icon.png"));
    }

    .message_icon {
      @include commom_icon(20px, 20px, url("@/assets/home/message_icon.png"));
    }

    .union_icon {
      @include commom_icon(16px, 14px, url("@/assets/user/union.png"));
    }

    .drawer_box {
      display: none;
    }
  }

  .operate_box {
    display: flex;
    align-items: center;
  }

  .setting {
    width: 16px;
    height: 16px;
    margin-left: 20px
  }

  .download_logo {
    @include commom_icon(20px, 20px, url("@/assets/common/download.png"));
  }

  .language_text {
    font-size: 14px;
    font-family: PingFang_Medium;
    font-weight: 500;
    color: $basicWhite;

    &:hover {
      color: $basicColor;
    }

    .line {
      display: inline-block;
      background-color: #5e6673;
      margin: 0 8px;
      height: 20px;
      width: 1px;
      vertical-align: middle;
    }
  }

  ::v-deep {
    .el-drawer__wrapper {
      display: none;
    }

    .el-drawer {
      background-color: #313237;
      width: 226px;
    }

    .el-drawer__close-btn {
      color: #B7BDC6;
    }

    .el-drawer__header {
      margin-bottom: 15px;
    }

    .union_logout {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      font-family: "PingFang_Regular";
      cursor: pointer;

      .login {
        font-size: 14px;
        font-weight: 500;
        color: $basicWhite;
        line-height: 14px;
        margin-bottom: 20px;
      }

      .union_btn {
        width: 186px;
        height: 34px;
        background-color: $basicColor;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 500;
        line-height: 34px;
        text-align: center;
      }

      .register {
        color: #141515;
        background-color: $basicColor;
        margin-bottom: 20px;
      }

      .download {
        background-color: #44454C;
        color: $basicWhite;
      }
    }

    .command_item {
      display: flex;
      align-items: center;
      padding: 11px 20px;
      font-size: 14px;
      font-family: PingFang_Medium;
      font-weight: 500;
      color: #B7BDC6;
      line-height: 14px;
      cursor: pointer;

      &:hover {
        background-color: #44454C;
        color: $basicColor;
      }

      .item_icon {
        width: 18px;
        height: auto;
        margin-right: 8px;
      }
    }

    .common_line {
      width: 100%;
      height: 1px;
      background-color: #44454C;
    }
  }
}

@media (max-width: 1000px) {
  .header_box {
    .right {

      .sign_box,
      .logged,
      .operate_box {
        display: none;
      }

      .drawer_box {
        display: flex;
        align-items: center;
      }
    }

    ::v-deep {
      .el-drawer__wrapper {
        display: block;
      }
    }
  }

}

@media (max-width: 1024px) {
  .header_box {
    .left {
      pointer-events: auto;
      visibility: visible;
    }
  }

}
</style>
<style lang="scss">
.el-dropdown-menu__item:hover {
  background-color: transparent;
}

.common_page_menu {
  top: 52px !important;
  background-color: #313237 !important;

  .el-dropdown-menu__item {
    min-width: 230px;
    padding: 0;
    line-height: 50px;

    &:hover {
      background-color: transparent !important;
    }
  }

  .trade_dropdown_box {
    // padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }

  .common_more_page {
    display: flex;
    flex-direction: column;
    min-width: 120px;
  }

  .commom_page_item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #b7bdc6;
    padding: 0 12px;

    &:hover {
      color: $basicColor;
      background-color: #44454C;

      .text-white {
        color: $basicColor;
      }
    }
  }

  .popper__arrow {
    display: none;
  }
}

.right_common_dropdown_menu {
  top: 52px !important;
  font-family: PingFang_Medium;
  font-weight: 500;

  &.el-dropdown-menu {
    margin: 0;
  }

  &.el-dropdown-menu {
    background-color: #313237;
  }

  .el-dropdown-menu__item {
    min-width: 181px;
    line-height: 52px;
    padding: 0 12px;
  }

  .userinfo {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #373b43;

    .user_logo {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      border-radius: 50%;
    }

    .name_text {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang_Bold;
      font-weight: bold;
      color: #ffffff;
      line-height: 20px;
      margin-bottom: 8px;

      &>img {
        width: 13px;
        height: 13px;
        margin-left: 5px;
        margin-right: 6px;
      }

      .level {
        padding: 1px 10px;
        font-size: 12px;
        font-family: PingFang_Regular;
        font-weight: 400;
        color: #989898;
        background-color: rgba($color: #989898, $alpha: 0.2);
        border-radius: 14px;
      }

      .verified {
        background-color: rgba($color: $basicColor, $alpha: 0.2);
        color: $basicColor;
      }

      .verifyFail {
        color: $basicErrorColor;
      }
    }

    .id_text {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFang_Regular;
      font-weight: 400;
      color: #989898;
      line-height: 12px;

      .el-button {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  .item_block {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFang_Medium;
    font-weight: 500;
    color: #b7bdc6;

    &:hover {
      color: $basicColor;
    }

    .item_icon {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }

  .nav_order_item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #b7bdc6;

    &:hover {
      color: $basicColor;
    }

    .nav_order_logo {
      width: auto;
      height: 15px;
      margin-right: 8px;
    }
  }

  .download_box {
    padding: 20px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: #ffffff;

    &>span {
      margin-bottom: 12px;
      line-height: 20px;
    }

    .download_qrcode_img {
      border-radius: 4px;
    }

    .more_btn {
      display: inline-block;
      width: 100%;
      line-height: 27px;
      background-color: rgba($color: #b7bdc6, $alpha: 0.2);
      border-radius: 14px;
      font-size: 12px;
      font-family: PingFang_Medium;
      color: #b7bdc6;
      padding: 0 19px;
      margin: 12px 0;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  // 专业版、标准版布局切换
  .layout-toggle {
    width: 298px;
    background-color: #2B3139;
    padding: 20px 20px 0;

    .layout-wrap {
      .layout-item {
        width: 119px;
        height: 73px;
        background-color: #434955;
        border-radius: 2px;

        .item-wrap {
          width: 97px;
          height: 53px;
          background-color: #070707;

          .line-wrap {
            width: 35px;
            height: 16px;
            background-color: #141515;

            .line {
              width: 14px;
              height: 4px;
            }
          }
        }
      }

      .border-active {
        border: 1px solid $basicColor;
      }

      .text-active {
        color: $basicColor;
      }
    }
  }
}

.hover_dropdown {
  .el-dropdown-menu__item:hover {
    background-color: #44454C !important;
  }
}

// .v-modal{
//   display: none;
// }
@media (max-width: 1110px) {
  .v-modal {
    display: block;
  }
}
</style>
