import Assets from '@/api/assets'
import store from '@/store'
import Decimal from 'decimal.js'
import { getNum, thousandths } from '@/utils'
import i18n from '@/i18n'
import global from '@/utils/global'
// import _ from 'lodash'
Decimal.set({ precision: 20 })
const orderStatusEnum = {
  1: i18n.t('message.assets.confrimed'),
  2: i18n.t('message.assets.success'),
  3: i18n.t('message.assets.fail')
}
// 记录列表
const handleRecordList = ({ data, listType }) => {
  if (data && data.length > 0) {
    return data.map((item) => {
      let type
      if (listType === 1) {
        type = i18n.t('message.assets.recharge')
      }
      if (listType === 2) {
        type = i18n.t('message.assets.withdraw')
      }
      if ([1, 2].includes(listType)) {
        // 充值 || 提现
        return {
          id: item.id,
          time: item.create_time,
          status: item.status,
          statusDesc: orderStatusEnum[item.status],
          coin: item.coin_code,
          quantity: item.amount,
          orderNo: item.order_no,
          color: item.status === 3 ? global.basicErrorColor : '#27B485',
          listType,
          type
        }
      } else {
        return {
          ...item,
          listType
        }
      }
    })
  }
  return []
}

// 记录详情
const handleRecordInfo = (info) => {
  if (info) {
    const { data } = info
    const tempData = { ...data }
    tempData.createTime = data.create_time
    // if (type === 1) {
    //   tempData.address = info.address
    // } else {
    //   tempData.address = info.address_in
    // }
    return tempData
  }
}

// 地址列表
const hanldeAddressList = (data) => {
  if (data && data.length) {
    const addressListObj = {}
    const addressList = data.map((item) => {
      const tempConvert = Decimal.mul(
        item.transfer_fee_usdt,
        store.state.user.rate_data.rate
      ).toNumber()
      item.convert = tempConvert ? thousandths(getNum(tempConvert, 2)) : '0.00'
      item.convertStr = `${store.state.user.rate_data.ratesymbol}${item.convert}`
      if (!addressListObj.hasOwnProperty(item.coin_code)) {
        addressListObj[item.coin_code] = item
      }
      return item
    })
    return { addressListObj, addressList }
  }
  return { addressListObj: {}, addressList: [] }
}

// 币种资产列表
const handleAssetsList = (data) => {
  const tempData = data.list || data
  if (tempData && tempData.length > 0) {
    const coinListObj = {}
    const coinList = []
    tempData.forEach((item) => {
      const tempTotal = item.total_num || Decimal.add(item.use_num, item.freeze_num).toString()
      if (data.data && data.data.hideSmallCoin && tempTotal <= 0) {
        return
      }
      const tempItem = { ...item }
      const tempRate = item.total_num_rate || item.usdt_num
      const digit = 8
      tempItem.btcNum = item.total_btc || item.btc_num
      const tempConvert = Decimal.mul(tempRate, store.state.user.rate_data.rate).toNumber()
      tempItem.convert = tempConvert ? thousandths(getNum(tempConvert, 2)) : '0.00'
      tempItem.total = tempTotal && tempTotal != 0 ? thousandths(tempTotal) : '0.00'
      tempItem.use = item.use_num && item.use_num != 0 ? thousandths(item.use_num) : '0.00'
      tempItem.freeze = item.freeze_num && item.freeze_num != 0 ? thousandths(item.freeze_num) : '0.00'
      tempItem.totalBtc = tempItem.btcNum ? getNum(thousandths(tempItem.btcNum), digit) : '0.00000000'
      if (!coinListObj.hasOwnProperty(tempItem.coin_code)) {
        coinListObj[item.coin_code] = tempItem
      }
      coinList.push(tempItem)
    })
    return { coinListObj, coinList }
  }
  return { coinListObj: {}, coinList: [] }
}

// 累计收益率
const handleHoldlogList = (data) => {
  let total = 0
  const dateList = []
  const radioList = []
  const upList = []
  const downList = []
  if (data && data.length > 0) {
    data.reverse()
    data.forEach((el) => {
      dateList.push(el.create_time)
      radioList.push(el.ratio)
      const isDown = el.ratio && el.ratio.indexOf('-') > -1
      downList.push(isDown ? el.ratio : '0')
      upList.push(!isDown ? el.ratio : '0')
      total = Decimal.add(total, el.ratio).toNumber()
    })
    return { total: getNum(total, 2), dateList, radioList, downList, upList }
  }
  return { total, dateList, radioList, downList, upList }
}

// 现货账户资产统计
const handleSpotcoin = (data) => {
  const tempData = { ...data }
  const rateData = store.state.user.rate_data
  tempData.base_num = thousandths(getNum(data.base_num, 8))
  tempData.baseusenum = thousandths(getNum(data.baseusenum, 8))
  const tempconvert1 = rateData.rate
    ? Decimal.mul(data.usdt_total, rateData.rate).toNumber()
    : data.usdt_total
  tempData.usdt_total = rateData.ratesymbol
    ? `≈ ${rateData.ratesymbol} ${thousandths(getNum(tempconvert1, 2))}`
    : ''
  const tempconvert2 = rateData.rate
    ? Decimal.mul(data.usdtusenum, rateData.rate).toNumber()
    : data.usdtusenum
  tempData.usdtusenum = rateData.ratesymbol
    ? `≈ ${rateData.ratesymbol} ${thousandths(getNum(tempconvert2, 2))}`
    : ''
  return tempData
}

// 资金账户资产统计
const handleAssetsAccountTotal = (data) => {
  const tempObj = { ...data }
  tempObj.btnNum = thousandths(getNum(tempObj.btcnum, 8))
  const tempConvert = Decimal.mul(tempObj.usdtnum, store.state.user.rate_data.rate).toNumber()
  tempObj.convert = tempConvert ? thousandths(getNum(tempConvert, 2)) : '0.00'
  tempObj.convertText = `≈ ${store.state.user.rate_data.ratesymbol} ${tempObj.convert}`
  return tempObj
}

// 总资产数量统计
const handleAssetsTotal = (data) => {
  const tempObj = { ...data }
  const tempConvert = store.state.user.rate_data.rate
    ? Decimal.mul(tempObj.sum_balance, store.state.user.rate_data.rate).toString()
    : tempObj.sum_balance
  tempObj.convert = tempConvert ? thousandths(getNum(tempConvert, 2)) : '0.00'
  tempObj.convertText = store.state.user.rate_data.ratesymbol
    ? `${store.state.user.rate_data.ratesymbol} ${tempObj.convert}`
    : ''
  tempObj.sumBtc = thousandths(data.sum_btc_balance)
  if (tempObj.detail_balance && tempObj.detail_balance.length > 0) {
    tempObj.detail_balance = tempObj.detail_balance.map((item) => {
      item.btcBalance = thousandths(item.btc_balance)
      item.balanceText = thousandths(item.balance)
      const temp = store.state.user.rate_data.rate
        ? Decimal.mul(item.balance, store.state.user.rate_data.rate).toString()
        : tempObj.balance
      item.convert = temp ? thousandths(getNum(temp, 2)) : '0.00'
      item.convertText = store.state.user.rate_data.ratesymbol
        ? `${store.state.user.rate_data.ratesymbol} ${item.convert}`
        : ''
      return item
    })
  }
  return tempObj
}

// 资产分布
const handleDistribute = (data) => {
  const result = []
  if (data && Object.keys(data).length > 0) {
    // let calRes = 0
    // if (_.values(data).length > 0) {
    //   _.values(data).forEach(item => {
    //     calRes = Decimal.add(calRes, item).toNumber()
    //   })
    // }
    Object.keys(data).forEach((item) => {
      const tempRes = data[item] > 0 ? getNum(data[item], 2) : '0.00'
      result.push({
        coin: item,
        proportion_str: tempRes || '0.00'
      })
    })
    return result
  }
}
const state = {
  show_assets:
    localStorage.getItem('assetsShow') == null
      ? true
      : JSON.parse(localStorage.getItem('assetsShow')),
  assets_total: {},
  spotcoin_total: {},
  holdlog_list: {
    total: '',
    dateList: [],
    radioList: [],
    downList: [],
    upList: []
  },
  coin_asset: [],
  filter_assets: {
    list: [],
    total: 0
  },
  record_list: {
    list: [],
    total: 0
  },
  otc_record_list: {
    list: [],
    total: 0
  },
  coin_list: [],
  coin_obj: {},
  deposit_list: [],
  deposit_obj: {},
  history_record: [],
  addressInfo: {},
  recordInfo: {},
  withdrawInfo: {},
  withdrawSecret: {},
  assetsAccountTotal: {},
  assetsDistribute: [],
  showCtc: false
}

const mutations = {
  showAssets(state, data) {
    state.show_assets = !state.show_assets
    localStorage.setItem('assetsShow', data ?? state.show_assets)
  },
  setAssetsTotal(state, data) {
    state.assets_total = handleAssetsTotal(data)
  },
  setSpotcointotal(state, data) {
    state.spotcoin_total = handleSpotcoin(data)
    state.assetsDistribute = state.spotcoin_total.coin_proportion_list
  },
  setHoldlogList(state, data) {
    state.holdlog_list = handleHoldlogList(data)
  },
  setCoinAsset(state, data) {
    state.coin_asset = data.list
    const { coinListObj, coinList } = handleAssetsList(data)
    state.coin_obj = coinListObj
    state.filter_assets.list = coinList
    state.filter_assets.total = coinList.length
  },
  filterAssets(state, data) {
    // console.log(data)
    const result = []
    if (data) {
      state.coin_asset.forEach((el) => {
        if (data.coin && el.coin_code.indexOf(data.coin.toUpperCase()) <= -1) {
          // 关键词存在&未找到关键词
          return
        }
        const tempTotal = el.total_num || Decimal.add(el.use_num, el.freeze_num).toNumber()
        if (data.hidden && Number(tempTotal) === 0) {
          // 筛选为0资产
          return
        }
        result.push(el)
      })
    }
    const { coinList } = handleAssetsList(result)
    state.filter_assets.list = coinList
    state.filter_assets.total = result.length
  },
  setRecordList(state, { result, listType }) {
    state.record_list.list = handleRecordList({ data: result.data_list, listType })
    state.record_list.total = result.data_total
  },
  setCoinList(state, data) {
    state.coin_list = data
  },
  setDepositList(state, data) {
    const { addressListObj, addressList } = hanldeAddressList(data)
    state.deposit_list = addressList
    state.deposit_obj = addressListObj
  },
  resetData(state) {
    state.assets_total = {}
    state.spotcoin_total = {}
    state.holdlog_list = {}
  },
  setAddressInfo(state, data) {
    state.addressInfo = data
  },
  setRecordInfo(state, data) {
    state.recordInfo = handleRecordInfo(data)
  },
  setWithdrawInfo(state, data) {
    state.withdrawInfo = data
  },
  setWithdrawSecret(state, data) {
    state.withdrawSecret = data
  },
  setOtcAssets(state, data) {
    state.assetsAccountTotal = handleAssetsAccountTotal(data.total)
    state.assetsDistribute = handleDistribute(data.statistics)
  },
  setCoinRecord(state, data) {
    state.history_record = data
  },
  setOtcAssetsRecord(state, data) {
    state.otc_record_list.list = data
    state.otc_record_list.total = data.length
  },
  setConfigCtc(state, data) {
    state.showCtc = data
  }
}

const actions = {
  async getAssetsTotal({ commit }) {
    try {
      const res = await Assets.getAssetsTotalApi()
      commit('setAssetsTotal', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getSpotcointotal({ commit }, data) {
    try {
      const res = await Assets.getSpotcointotalApi(data)
      commit('setSpotcointotal', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getHoldlogList({ commit }, data) {
    try {
      const res = await Assets.getHoldlogListApi(data)
      commit('setHoldlogList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCoinAsset({ commit }, data) {
    try {
      const res = await Assets.getCoinAssetApi()
      commit('setCoinAsset', { list: res.data, data })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getRecordList({ state, commit }, params) {
    try {
      state.record_list = {
        list: [],
        total: 0
      }
      const res = await Assets.getRecordListApi(params)
      if (params.tabType == 4) {
        store.commit('spotOrder/setHistoryList', res.data)
      } else {
        commit('setRecordList', { result: res.data, listType: params.tabType })
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCoinList({ commit }) {
    try {
      const res = await Assets.getCoinListApi()
      commit('setCoinList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDepositList({ commit }, params) {
    try {
      const res = await Assets.getDepositListApi(params)
      commit('setDepositList', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDepositAddress({ commit }, params) {
    try {
      const res = await Assets.getDepositAddressApi(params)
      commit('setAddressInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getRecordInfo({ commit }, params) {
    try {
      const res = await Assets.getRecordInfoApi(params)
      commit('setRecordInfo', { data: res.data, type: params.type })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getWithdrawInfo({ commit }, params) {
    try {
      const res = await Assets.getWithdrawInfoApi(params)
      commit('setWithdrawInfo', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async withdrawCaptcha({ commit }, params) {
    try {
      const res = await Assets.withdrawCaptchaApi(params)
      commit('setWithdrawSecret', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async toWithdraw(no, params) {
    try {
      await Assets.withdrawApi(params)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getOtcAssets({ commit }) {
    try {
      const res = await Assets.getOtcAssetsApi()
      commit('setOtcAssets', res.data)
      commit('setCoinAsset', { list: res.data.data_list })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getCoinRecord({ commit }, params) {
    try {
      const res = await Assets.getCoinRecordApi(params)
      commit('setCoinRecord', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async otcAssetsRecord({ commit }, params) {
    try {
      const res = await Assets.otcAssetsRecordApi(params)
      commit('setOtcAssetsRecord', res.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async apiConfigCtc({ commit }, params) {
    try {
      const res = await Assets.configCtc()
      commit('setConfigCtc', res.data?.show ?? false)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
