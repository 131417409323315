export const message = {
  common: {
    noData: '无数据',
    tips: '温馨提示：',
    all: '全部',
    confirm: '确定',
    cancel: '取消',
    opre: '操作',
    price: '价格',
    num: '数量',
    trP: '触发价',
    login: '登录',
    regis: '注册',
    sus: '永续',
    day: '一天',
    week: '一周',
    oneMonth: '一个月',
    threeMonth: '三个月',
    search: '查询',
    reset: '重置',
    to: '至',
    startT: '开始时间',
    endT: '结束时间',
    updateT: '更新时间',
    updateS: '修改成功',
    orderS: '下单成功',
    successTips: '操作成功',
    uploadSucc: '上传成功',
    handicap: '订单簿',
    puzzle: '向右滑动完成拼图',
    downloadSucc: '下载成功'
  },
  tips: {
    orderWarn: '开启订单确认功能后，每次提交订单时都会提示确认弹窗。该设置仅对币本位合约生效。',
    positionWarn:
      '若币本位合约存在持仓或者挂单，不允许调整仓位模式。仓位模式调整对所有合约统一生效。该设置仅对币本位合约生效。',
    orderWarnFut: '开启订单确认功能后，每次提交订单时都会提示确认弹窗。该设置仅对U本位合约生效。',
    positionWarnFut:
      '若U本位合约存在持仓或者挂单，不允许调整仓位模式。仓位模式调整对所有合约统一生效。该设置仅对U本位合约生效。'
  },
  assets: {
    spot: '现货账户',
    fut: '合约账户',
    my: '我的资产',
    amount: '资金账户',
    usdc: 'USDC合约账户',
    financial: '理财账户',
    order: '订单',
    recharge: '充值',
    withdraw: '提现',
    transfer: '划转',
    record: '资金记录',
    total: '总资产',
    totalVal: '总估值',
    use: '可用余额',
    gainTotal: '累计收益率',
    day7: '近7天',
    day30: '近30天',
    time: '自定义时间',
    dustribute: '资产分布',
    searchP: '请输入搜索币种',
    hidden: '隐藏小额资产',
    coin: '币种',
    all: '全部',
    available: '可用',
    freeze: '冻结',
    value: 'BTC估值',
    searchTime: '检索的时间范围不得超过12个月。',
    start: '开始时间',
    inputTime: '请输入开始时间',
    endTime: '结束时间',
    inputET: '请输入结束时间',
    continue: '继续',
    custom: '自定义',
    rechargeTitle: '数字货币充值',
    checkCoin: '选择币种',
    addressR: '充值地址',
    networkR: '转账网络',
    getAddressTips: '请点击按钮获取充值地址。',
    getAddress: '获取地址',
    toAccount: '预计到账',
    unlock: '预计解锁',
    confrim: '{num}次网络确认',
    minQuan: '最小充币数量',
    depositAccount: '充币账户',
    change: '更改',
    confrimNetwork: '请再次确认您选择的主网络是',
    rechargeTips1: '充值未到账？',
    rechargeTips2:
      '如果您在充值过程中遇到以下问题,您可以通过“充值未到账查询”功能查询当前充值状态或自助申请找回您的资产。',
    rechargeTips3: '充值后长时间未到账',
    rechargeTips4: '充值时忘填或错填Tag/Memo',
    search: '立即查询',
    showDetail: '查看详情',
    showAll: '查看全部',
    faq1: '视频教程',
    faq2: '如何向我的账户充值数字资产',
    faq3: '为什么我充的币一直不到账?',
    faq4: '填错、漏填Memo/Tag的时候,如何找回我的资产？',
    faq5: '如何购买数字货币？',
    faq6: '查看全部币种充提状态',
    rechargeRecord: '近期记录',
    tips: '充值未到账，点这里',
    timeT: '时间',
    assets: '资产',
    quan: '数量',
    address: '地址',
    transferID: '转账ID',
    status: '订单状态',
    success: '已完成',
    confrimed: '进行中',
    fail: '失败',
    dialogTips: '确保您选择的充币网络与您在提币平台使用的网络一致,否则资产可能会丢失。',
    receive: '此地址只可接收',
    dialogTitle1: '请选择充值币种',
    dialogTitle2: '选择网络',
    dialogTitle3: '使用交易平台APP或钱包APP扫码',
    dialogTitle4: '充值详情',
    detail1: '状态',
    detail2: '充值数量',
    networkTips: '该币种已经暂停提现，更多信息请留意官网公告',
    minError: '不能小于最小提现数量{num}',
    maxError: '不能大于最大提现数量{num}',
    addressError: '请填写提现地址',
    stopRecharge: '暂停充值',
    importantTips: '重要提示',
    tipsDesc: '该币种已经暂停充值，更多信息请留意官网公告',
    hasKnow: '我已知晓',
    moreQuota: '您当日累计提现额度大于24小时提现额度',
    shortBalance: '可用余额不足',
    sellCoin: '卖币',
    withdrawTitle: '数字货币提现',
    withdraw1: '选择提现币种',
    withdraw2: '24h已用额度',
    withdraw3: '24h提现额度',
    withdraw4:
      '·为保障资金安全，当您账户安全策略变更、密码修改、我们会对提现进行人工审核，请耐心等待工作人员电话或邮件联系',
    withdraw5: '·请务必确认电脑及浏览器安全，防止信息被篡改或泄露。',
    withdraw6:
      '·您所提的数字资产为一币多链资产，无论您选择从哪个链上提出，均意味着您所拥有的该提现页面展示的数字资产总量减少相应的提出数量。',
    withdraw7: '填写提现信息',
    withdraw8: '提现地址',
    withdraw9: '填写提现地址到这里',
    withdraw10: '提现网络',
    withdraw11: '提现数量',
    withdraw12: '最小输入数量为',
    withdraw13: '实际到账',
    withdraw14: '您选择的网络是',
    withdraw15: '网络，请确保您的提现地址支持',
    withdraw16:
      '网络。如果其他平台不支持，您的资产可能会丢失。如果不确定接收方是否支持，建议取消本次提现，以免造成资金损失。',
    withdraw17: '我确认已知晓',
    withdraw18: '风险提示',
    withdraw19:
      '1.提现完成后资产的所有权将归属于目标地址的所有人，请确保地址准确无误，提交提现申请后将无法撒销',
    withdraw20:
      '2.请确保您清楚了解资产用途以及可能产生的风险，警惕任何形式的传销、非法集资、资金骗局等。请特别警惕假冒官方的电话及短信，常见诈骗行为为恐吓或诱导您登录钓鱼网站、或让您进行提现、转账到指定地址，导致受损！',
    withdraw21: '我对资产提取可能存在的风险清楚了解，并确认继续提取。',
    withdraw22: '请确保您输入了正确的提现地址并且您选择的转账网络与地址相匹配。',
    withdraw23: '提现订单创建后不可取消。',
    withdraw24: '提现申请已提交',
    withdraw25: '预计到帐',
    withdraw26: '提现审核中',
    withdraw27:
      '您的提现申请已进入审核中，预计审核时间12小时请耐心等待。您可在提现记录页面查看此次提现的状态。',
    withdraw28: '成功',
    withdraw29: '请选择提现币种',
    withdraw30: '提现账户',
    withdraw31: '到账',
    withdraw32: '请选择提现网络',
    activeTitle1: '充值记录',
    activeTitle2: '提现记录',
    activeTitle3: '划转记录',
    activeTitle4: '现货交易',
    activeTitle5: '返佣',
    activeTitle6: '转账',
    from: '从',
    to: '至',
    tranError: '当前币种无可划转资产，请选择其他币种'
  },
  spot: {
    search: '搜索',
    tradePair: '交易对',
    price: '价格',
    riseAndFall: '涨跌',
    latestTransaction: '最新成交',
    amount: '数量',
    time: '时间',
    highestPrice: '最高价',
    lowerPrice: '最低价',
    turnover: '成交量',
    volume: '成交额',
    moment: '分时',
    minute: '分钟',
    hour: '小时',
    day: '天',
    week: '周线',
    month: '月线',
    selectPeriod: '选择周期',
    edit: '编辑',
    save: '保存',
    depthMap: '深度图',
    limitedPrice: '限价',
    marketPrice: '市价',
    stopProfitAndLoss: '限价止盈止损',
    limitedPriceTip: '限价委托是指以特定或更优价格进行买卖,限价单不能保证执行',
    marketPriceTip: '市价委托是指以目前市场上可获得的最优价格进行快速买卖',
    stopProfitAndLossTip:
      '止盈止损订单：当市场最新价达到触发价格时，按预先设置的委托价格和数量自动下单',
    available: '可用',
    triggePrice: '触发价',
    buy: '买入',
    sale: '卖出',
    transIn: '转入',
    transOut: '转出',
    login: '登录',
    or: '或',
    other: '或者',
    partialTransaction: '部分成交',
    completeTransaction: '完全成交',
    canceled: '已取消',
    registerNow: '立即注册',
    batchCancel: '批量撤销',
    orderNo: '订单号',
    transactionPrice: '成交价格',
    startDate: '开始日期',
    endDate: '结束日期',
    buySaleDirection: '买卖方向',
    buySaleGroup: '买卖盘',
    buyGroup: '买盘',
    saleGroup: '卖盘',
    currentDelegation: '当前委托',
    historicalEntrustment: '历史委托',
    historicalTransaction: '历史成交',
    type: '类型',
    direction: '方向',
    entrustedPrice: '委托价格',
    entrustedAmount: '委托数量',
    numberOfTransactions: '已成交数量',
    transationNum: '成交数量',
    transationAmount: '成交金额',
    fee: '手续费',
    rate: '汇率',
    role: '角色',
    more: '查看更多',
    noData: '暂无数据',
    entrustedVarieties: '委托品种',
    mode: '方式',
    finalPrice: '成交价',
    conductTransactions: '后开始现货交易',
    scriptName: '脚本名称',
    lessPrice: '当价格下跌至或低于',
    higherPrice: '当价格上涨至或高于',
    present: '时',
    triggerTo: '则触发以',
    boughtAt: '的价格买入',
    saleAt: '的价格卖出',
    ordersFor: '的订单',
    submit: '提交',
    study: '指标',
    orderConfirmation: '下单确认',
    confirmCancelOrder: '确认撤销该订单',
    confirmCancelAllOrder: '确认撤销全部订单',
    successfullyCancelledDelegation: '取消委托成功',
    successfullyBatchCancelledDelegation: '批量撤销委托成功',
    availableBalance: '可用余额为',
    maxBuy: '最多买入',
    maxSale: '最多卖出',
    minOrderNum: '最小下单金额为',
    currentAvailable: '当前可用',
    enterPrice: '请输入价格',
    enterNum: '请输入数量',
    enterVolume: '请输入成交额',
    enterTriggerPrice: '请输入触发价',
    enterlimitedPrice: '请输入限价',
    degreeOfCompletion: '完成度',
    amountOfMoney: '金额',
    triggerConditions: '触发条件',
    operation: '操作',
    status: '状态',
    copy: '复制',
    copySuccess: '复制成功',
    saveImg: '保存图片',
    order1: '请选择交易对',
    order2: '加载中',
    order3: '委托时间',
    order4: '名称',
    order5: '成交总额',
    order6: '取消委托',
    order7: '详情',
    shape1: '美国线',
    shape2: 'K线图',
    shape3: '空心K线图',
    shape4: '平均K线图',
    shape5: '线形图',
    shape6: '面积图',
    shape7: '基准线',
    snapshot: '快照',
    fullScreen: '全屏'
  },
  futures: {
    fut: '合约',
    futOrder: '合约订单',
    all: '全仓',
    isolated: '逐仓',
    single: '单向持仓',
    double: '双向持仓',
    more: '做多',
    empty: '做空',
    position: '当前杠杆倍数最高可持有头寸：',
    openP: '开仓价格',
    closeP: '平仓价格',
    quan: '成交数量',
    wallet: '钱包余额',
    calc: '计算',
    res: '计算结果',
    bond: '起始保证金',
    earn: '收益',
    rate: '回报率',
    liqP: '强平价格',
    dire: '使用说明',
    tipsContent: '提前查看交易的潜在风险和回报，通过使用合约计算器来了解交易的潜在盈利或者亏损。',
    trans: '划转',
    transTip: '平台内部转账不收取手续费',
    label1: '币种',
    label2: '数量',
    useassets: '可用资产',
    uStand: 'U本位合约',
    cStand: '币本位合约',
    sureP: '保证金额',
    walletP: '钱包余额',
    pnl: '未实现盈亏',
    currentPosi: '当前仓位',
    futCurrentPosi: '仓位',
    markP: '标记价格',
    open: '开仓',
    close: '平仓',
    limit: '限价',
    market: '市价',
    profirLoss: '止盈止损',
    pnlDialog: '止盈/止损',
    entrust: '委托',
    buy: '买入',
    sell: '卖出',
    openMore: '开多',
    openEmpty: '开空',
    use: '可用',
    margin: '保证金',
    buyMore: '买入开多',
    sellEmpty: '卖出开空',
    up: '（看涨）',
    down: '（看跌）',
    flatOut: '买入平空',
    pinto: '卖出平多',
    buyLong: '买入/做多',
    sellShort: '卖出/做空',
    can: '可',
    closeMore: '平多',
    closeEmpty: '平空',
    buyTip: '市场最优价格买入',
    sellTip: '市场最优价格卖出',
    dealTip: '市场最优价格成交',
    new: '最新',
    mark: '标记',
    assetsTitle: 'U本位合约资产',
    keepM: '维持保证金',
    mAmount: '保证金余额',
    mRate: '保证金率',
    tab1: '当前持仓',
    tab2: '当前委托',
    tab3: '历史委托',
    tab4: '成交记录',
    tab5: '资金流水',
    th1: '合约品种',
    th2: '时间',
    th3: '仓位类型',
    th4: '委托类型',
    th5: '方向',
    th6: '已成交数量',
    th7: '触发条件',
    th8: '平均价格',
    th9: '状态',
    th10: '成交均价',
    th11: '手续费',
    th12: '角色',
    th13: '已实现盈亏',
    th14: '撤单',
    first: '请先',
    or: '或',
    tradeText: '后开始合约交易',
    orderNo: '订单',
    total: '总',
    fee: '手续费',
    pAL: '盈亏',
    dealP: '成交价格',
    turnover: '成交额',
    marginTitle: '{coin}永续保证金模式',
    leverageTitle: '调整杠杆倍数',
    modeTips: '· 调整保证金模式仅对当前合约生效',
    modeIntroTitle: '什么是全仓和逐仓模式？',
    modeIntro1:
      '全仓保证金模式: 保证金资产相同的全仓仓位共享该资产的全仓保证金。在强平事件中，交易者可能会损失全部该保证金和该保证金资产下的所有全仓仓位。',
    modeIntro2:
      '逐仓保证金模式: 一定数量保证金被分配到仓位上。如果仓位保证金亏损到低于维持保证金的水平，仓位将被强平。在逐仓模式下，您可以为这个仓位添加和减少保证金。',
    errorMsg: '当前合约存在持仓或挂单，不支持调整保证金模式',
    totalAmount: '总额',
    type: '类型',
    leverErrorTips1:
      '选择超过 [10x] 杠杆交易会增加强行平仓风险，请注意相关风险，更多信息请参考帮助中心。',
    leverErrorTips2: '杠杠过低：保证金余额不足，无法调整杠杠',
    preferences: '偏好设置',
    option1: '下单确认',
    option2: '仓位模式',
    positionModeDesc1: '单项持仓模式下，一个合约只允许持有一个方向的仓位',
    positionModeDesc2:
      '双向持仓模式下，一个合约可允许同时持有多空两个方向的仓位，并且同一个合约下不同方向仓位风险对冲',
    onlyNow: '只看当前品种',
    triggerP: '触发价格',
    triggerT: '触发类型',
    triggerTips1: '当标记价格到达',
    triggerTips3: '时，将会触发{orderType}委托，预计盈亏为',
    pnlTips:
      '· 该止盈止损针对该仓位。当全部手动平仓后该止盈止损将被自动取消。当价格到达设定的触发价格时，将执行限价/市价平仓委托。如果仓位数量超过市价委托单笔最大数量限制时，委托将被拒绝。',
    canSub: '最多可减少',
    adjust: '调整保证金',
    add: '增加',
    sub: '减少',
    max: '最大',
    positionMargin: '当前仓位保证金',
    canAdd: '最大可增加',
    liquidation: '调整后参考强平价',
    minNum: '最小下单数量为',
    closeAll: '一键平仓',
    cancelAll: '一键撤单',
    closeTips: '请确认是否要以市价全部平仓所有仓位？',
    cancelTips: '确定取消全部订单？',
    order: {
      fullDelegation: '全部委托',
      limitDelegation: '限价委托',
      marketDelegation: '市价委托',
      limitPriceProfitOrLoss: '限价止盈止损',
      marketPriceProfitOrLoss: '市价止盈止损',
      closeDelegation: '强平委托',
      all: '全部',
      buy: '买入',
      sale: '卖出',
      allTrade: '完全成交',
      cancel: '取消',
      expired: '已过期',
      serviceCharge: '手续费',
      spotTransfer: '现货划转',
      contractTransfer: '合约划转',
      realizedProfitAndLoss: '已实现盈亏',
      liquidation: '爆仓清算',
      capitalExpenses: '资金费用',
      allContracts: '全部合约',
      allDelegation: '全部委托',
      allTypes: '全部类型',
      allStatus: '全部状态',
      allAssets: '全部资产'
    }
  },
  user: {
    login: '登录',
    regisBtn: '注册',
    regis: '欢迎注册TOKEX',
    loginT: '欢迎登录',
    phoneNum: '手机号',
    phoneNumber: '手机号码',
    phone: '手机',
    email: '邮箱',
    google: '谷歌',
    ver: '验证',
    verCode: '验证码',
    emailA: '邮箱地址',
    password: '密码',
    confirmP: '确认密码',
    invite: '邀请码',
    option: '选填',
    inputPh: '请输入手机号',
    inputPhN: '请输入手机号码',
    inputE: '请输入邮箱地址',
    inputP: '请输入8-32位数字、字母或字符的组合',
    compareP: '两次密码输入不一致',
    inputPass: '请输入密码',
    inputNewPass: '请输入新密码',
    verifyPass: '请确认密码',
    agree: '我已阅读并同意',
    protocol: '用户协议',
    agreeError: '请先同意用户协议',
    alAccount: '已有账号',
    toLogin: '点击登录',
    search: '搜索',
    input: '请输入您在',
    code: '收到的6位验证码，验证码30分钟有效',
    getCode: '获取验证码',
    submit: '提交',
    notReceiveCode: '未收到验证码',
    notReceiveE: '未收到确认邮件？',
    know: '已知晓',
    smsSuccess: '发送短信成功，若仍未收到验证码，可尝试以下操作',
    emailSuccess: '邮箱验证码发送成功。如果您未收到，可尝试以下操作',
    smsTips1: '尝试语音验证',
    smsTips2: '检查绑定的手机号是否欠费',
    smsTips3: '检查是否位于短信垃圾箱中',
    smsTips4: '请确认您的手机号为{num}',
    smsTips5: '可能存在发送延迟，过10分钟后再重试',
    smsTips6: '若此电话号已注册过，我们就不会再向您发送验证码',
    emailTips1: '检查是否位于垃圾桶中',
    emailTips2: '请确认您的邮箱为{email}',
    emailTips3: '可能存在几分钟延迟，请过20分钟以后再试',
    emailTips4: '设置邮件地址白名单（如何设置电子邮件白名单）',
    emailTips5: '若此邮箱已注册过，我们就不会再向您发送验证码，请登录或重置您的密码',
    regisS: '恭喜你注册成功',
    successTips: '只需两步骤，即可开启数字货币之旅',
    jump: '跳过此步骤',
    getMore: '完成身份认证可得更多收益',
    forgetP: '忘记密码',
    imLogin: '立即登录',
    noAccount: '没有账号',
    toRegis: '点击注册',
    qrcodeLogin: '点击扫码登录',
    accountLogin: '账户密码登录',
    openApp: '打开APP，扫码登录',
    qrcodeExp: '二维码已过期，请刷新二维码',
    updateQrcode: '刷新二维码',
    confirmLogin: '扫码成功，请在手机上确认登录',
    safeVei: '安全验证',
    safeVeiTips: '为了您的账户安全，请输入收到的6位数字验证码',
    sended: '验证码已发送',
    nextS: '下一步',
    reSend: '后重新获取',
    loginS: '登录成功，正在跳转',
    confirmU: '确认更改',
    passEditTip:
      '为了保护您的财产安全，更改或解绑验证后，该账户将禁用支付服务、提币、C2C卖币，持续时间24小时。',
    remind: '温馨提示',
    back: '返回',
    noExist: '账户不存在',
    resetP: '重置密码',
    newP: '新密码',
    oldP: '旧密码',
    confirmNewP: '重新输入新密码',
    errorPM: '至少8位字符，必须包含1位大写字母以及1位字符',
    editPassSucc: '密码修改成功',
    editPassTips: '出于安全考虑，重置密码后该账户将禁用支付服务、提币、C2C卖币，持续时间24小时。',
    verify: '身份认证',
    verifyTips: '完成身份认证，有助于保护账户安全，提高提现额度及交易权限',
    oneVeri: '初级认证',
    twoVeri: '中级认证',
    threeVeri: '高级认证',
    basicInfo: '基本信息',
    nickname: '昵称',
    avatar: '头像',
    doubleVer: '双重验证（2FA）',
    safeLevel: '当前您的安全强度等级',
    phoneVer: '手机验证',
    emailVer: '邮箱验证',
    googleVer: '谷歌验证',
    highSafe: '高级安全',
    loginPass: '登录密码',
    set: '设置',
    edit: '修改',
    change: '更改',
    close: '关闭',
    open: '开启',
    low: '弱',
    middle: '中',
    high: '高',
    setNickname: '设置昵称',
    editNickname: '修改昵称',
    inputNickname1: '请输入昵称',
    nicknamePlace: '请输入不超过20个字符',
    errorNickname: '昵称长度必须为5-20个字符',
    inputNickname: '输入昵称',
    chooseAvatar: '选择头像',
    confirmBtn: '确认',
    avatarTips: '选择一个头像用作您的个人资料照片',
    avatarErrorTips: '请选择头像',
    defaultText: '默认',
    changeTips: '为了保护您的资产安全，更改或解绑验证后该账户将禁用支付务、提币和cC卖币24小时',
    inputPhoneCode: '请输入手机验证码',
    inputEmailCode: '请输入邮箱验证码',
    inputGoogleCode: '请输入谷歌验证码',
    phoneCode: '手机验证码',
    emailCode: '邮箱验证码',
    googleCode: '谷歌验证码',
    inputNewPhone: '输入新的手机号',
    inputNewEmail: '输入新的邮箱',
    inputNewPhoneCode: '新手机号验证码',
    inputNewEmailCode: '新邮箱验证码',
    please: '请',
    safeVeriNoUse: '安全验证不可用',
    bindGoogleStep1: '下载谷歌验证器APP',
    step1Tips1: 'IOS用户登录 App Store搜索 Authenticator下载。',
    step1Tips2: '安卓用户登录应用商店或使用手机浏览器搜索谷歌验证器下载。',
    bindGoogleStep2: '在谷歌验证器中添加密钥井备份',
    step2Tips: '打开谷歌验证器，扫描下方二维码或手动输入下述密钥添加验证令牌',
    copyKey: '复制密钥',
    bindGoogleStep3: '输入谷歌验证器中6位验证码',
    googleIntro: '谷歌验证器是什么',
    googleIntroText:
      '谷歌验证器是一款动态口令工具,工作原理类短信动态验证。绑定后每30s生成一个动态验证码,验证码可用于登录提现、修改安全设置等操作的安全验证。',
    copyS: '复制成功',
    phonetips1: '您确定要更改手机验证吗？',
    phonetips2: '为了保护您的资产安全，更改或解绑验证后该账户将禁用支付服务、提币和c2c卖币24小时。',
    phonetips3: '您确认要关闭手机验证吗？',
    phonetips4: '为了保护您的资产安全，更改或关闭验证后该账户将禁用支付服务、提币和c2c卖币24小时。',
    emailtips1: '您确定要更改邮箱验证吗？',
    emailtips2: '您确认要关闭邮箱验证吗？',
    emailtips3: '被解绑或被更改的邮箱在30天内不允许注册，并且在30天内不允许绑定或更改到其他帐户',
    emailtips4: '提币等相关操作需要开启两项安全验证，仅保留一项将影响提币！',
    googletips1: '您确定要关闭谷歌验证器吗？',
    save: '保存',
    new: '新',
    closeGoogleCode: '关闭谷歌验证器'
  },
  quotes: {
    center: '行情中心',
    search: '搜索币种',
    gain: '涨跌幅',
    low24: '24h最低',
    high24: '24h最高',
    vol24: '24h成交额',
    light: '亮点币种',
    new: '最新上币',
    optional: '自选',
    spot: '现货',
    fut: '合约',
    all: '全部',
    innovation: '创新区',
    metaverse: '元宇宙',
    perpetual: '永续',
    mock: '币本位合约'
  },
  home: {
    title: '欢迎来到',
    subTitle: '值得信赖的友好交易所',
    placeholder1: '请输入您的手机号或邮箱',
    toRegister: '通过电子邮件或电话注册',
    hot: '热门',
    main: '主流',
    new: '新币',
    gain: '涨幅',
    list: '榜',
    dealP: '交易对',
    newP: '最新价',
    gain24: '24h涨跌',
    quotes: '行情趋势',
    operate: '趋势',
    deal: '交易',
    more: '查看更多',
    introT: '值得用户信赖的加密货币交易平台',
    introSubT: 'TOKEX 竭力承诺以严格协议和行业领先的技术措施为用户安全保驾护航',
    intro1: '个性化定制页面',
    intro2: '自由设置昵称、NFC专属头像，背景色等自由定制信息数据通过<br>端到端加密获得保障。',
    intro3: '行业的行情机制',
    intro4: '实时动态的行情数据、精准的K线图，更清晰的掌握行情趋势，<br>推送无延迟。',
    intro5: '资产丰富全面',
    intro6: '多种策略，一键赋能，轻松实现数字资产量化交易高效的资产<br>盈亏分析模块。',
    toUse: '开始使用',
    advantage: '为什么要选择TOKEX？',
    advantageTips: '三大核心优势，国际领先技术',
    advantageDescT1: '金融级安全防护',
    advantageDesc1:
      '无偏性零知识证明隐私保密技术保护用户隐私,基于多种签名、离线签名、分层架构等安全设计，采用成熟的冷、热钱包分离技术。',
    advantageDescT2: '高性能撮合引擎',
    advantageDesc2:
      '自主研发的撮合交易引擎，采用先进的分布式集群架构及微服务开发方式 。支持超过300万/秒交易并发。',
    advantageDescT3: '高效智能系统',
    advantageDesc3:
      '交易系统上线至今从未发生任何停机故障。<br>通过全程热更新和灰度发布,7x24h智能监控<br>和态势感知实时进行漏洞扫描。智能和效率并存。',
    downloadTitle: '随时随地 多平台终端交易',
    downloadSubTitle: '下载移动端，财富密码尽在手中',
    download: '扫码下载，随时随地开启交易',
    downloadTips1: '无论是APP还是WEB，都可以快速开启您的交易',
    community: '加入TOKEX社区',
    support: '客服7 x 24小时在线支持，随时提供技术支持',
    toDeal: '即刻开始交易',
    give1: '注册并交易即可获得',
    give2: '和价值',
    give3: '理财体验金奖励！',
    toRegis: '立即注册',
    startDeal: '开始交易',
    qrcode: '二维码'
  },
  footer: {
    community: '社区',
    aboutUs: '关于我们',
    product: '产品',
    serve: '服务',
    help: '帮助中心',
    spotDeal: '现货交易',
    fiatDeal: 'C2C交易',
    sustainable: '永续合约',
    wallet: '钱包服务',
    download: '移动端下载',
    derivatives: '金融衍生品',
    upCoin: '上币申请',
    buyCoin: '一键买币',
    rebate: '返佣计划',
    bulk: '场外大宗',
    custom: '大客户权益',
    Affiliate: 'Affiliate',
    service: '在线客服',
    rate: '费率标准',
    api: 'API文档',
    suggest: '建议与反馈',
    develop: '功能待开发'
  },
  header: {
    wallet: '钱包',
    order: '订单',
    buyCoin: '买币',
    quickCoin: '快捷买币',
    oneTrading: '流程简单，一键买卖',
    c2c: 'C2C买币',
    free: '自由选择，价格更优',
    quote: '行情',
    c2cTrad: 'C2C交易',
    fastTrading: '快捷交易',
    derivatives: '衍生品',
    uFut: 'U本位合约',
    mockFut: '模拟合约',
    more: '更多',
    manage: '理财',
    newbie: '新手福利',
    download: '扫描下载APP',
    moreClient: '更多客户端',
    phone: '手机和桌面客户端',
    overview: '资产总览',
    safe: '安全中心',
    auth: '身份认证',
    invite: '邀请好友',
    logout: '退出登录',
    spotOrder: '现货订单',
    c2cOrder: 'C2C订单',
    futOrder: '合约订单',
    walletMenuItem1: '钱包总览',
    walletMenuItem2: '现货账户(充币&提币)',
    walletMenuItem3: '合约账户',
    walletMenuItem4: '期权账户',
    walletMenuItem5: '资金账户',
    moreMenuItem1: '矿池',
    moreMenuItem2: '钱包',
    moreMenuItem3: 'Tokex云',
    moreMenuItem4: '合伙人',
    moreMenuItem5: '资金安全',
    language: '语言',
    currency: '本地货币',
    layout: '布局',
    standard: '标准版',
    professional: '专业版',
    api: 'API管理',
    down: '下载'
  },
  notice: {
    title: '通知',
    hidden: '隐藏已读',
    mark: '全部标记为已读',
    more: '查看更多'
  },
  apiKey: {
    title: 'API管理'
  },
  TransferMoney: {
    title: '内部转账',
    payee: '收款人',
    amount: '金 额',
    amount2: '金额',
    confirm: '确 认',
    description: '通过用户ID向Tokex用户转账数字货币',
    next: '下一步',
    pre: '上一步',
    used: '常用收款人',
    userid: '用户ID',
    transfer: '转账',
    delete: '删除',
    remarks: '添加备注',
    add: '添加收款人',
    tbremarks: '备注',
    operation: '操作',
    cancel: '取消',
    adRemark: '备注(选填)',
    adPayee: '添加收款人',
    payeeId: '收款人ID',
    edit: '编辑备注',
    modify: '添加备注',
    warn1: '请输入收款人ID',
    warn2: '请输入数字',
    save: '保存',
    coin: '币种',
    collect: '收款',
    inputAount: '输入金额',
    inputRemark: '输入转账备注(对方可见)',
    available: '可用余额',
    transWarn: '无法转到此账户，请确保这是个有效并完成认证的账号，且收款方开启了用户ID向我转账',
    deletePrompt: '您确定要删除联系人么',
    leave: '您将要离开这个页面您输入的付款信息会被删除确认离开',
    verify: '为了您的账户安全，请完成以下验证操作',
    sub: '提交',
    remarkInfo: '备注信息',
    payWay: '支付方式',
    payNum: '付款金额',
    wallet: '现货钱包',
    success: '支付成功',
    to: '到',
    transeWay: '转账方式',
    date: '日期',
    orderNo: '订单号',
    other: '对方账号',
    tradeDetail: '交易详情',
    amoutTip: '可用余额不足',
    amoutTip2: '请输入整数或者小数',
    amoutTip3: '小数位过长',
    tip: '用户ID可以被支持转账。请输入用户ID在输入框中。接收方可以在首页-右上角头像-个人资料找到用户ID。请接收方分享用户ID给你。',
    know: '已知晓',
    sendagain: '再次发送',
    back: '返回',
    setting: '接收设置',
    setting1: '通过用户ID接受资金',
    setting2: '其他用户可通过收款方的用户ID向收款方的资金钱包转账资产。'
  },
  delivery: {
    sheet: '张',
    quanTips1: '仓位数量: {num} 张',
    quanTips2: '数量: {num} 张 ≈ {calcNum} {coin}',
    assetsTitle: '币本位资产',
    bondTitle: '保证金比率',
    onlySub: '只减仓',
    share: '持仓分享',
    shareText: '您可选择是否分享以下信息',
    leve: '杠杆倍数',
    pnlP: '盈亏金额',
    time: '时间标记'
  }
}
