let webSocket = null
const webSocketMap = {}
const successList = {}
let isConnect = false // 连接状态
// import { Message } from 'element-ui'
import { EventEmitter } from 'events'
let isOnInit = false
const evt = new EventEmitter()
let symbol = ''
let url = ''
let hasConnectNum = 0
let subList = []

// socket连接的配置
let socketOptions = {}

function CreatSocketOption() {
  return {
    binaryType: 'arraybuffer',
    openAutoConnect: true,
    maxConnectNum: 10, // -1 表示没有连接次数限制
    // 成功之后的回调
    success: () => {
      console.log('测试成功')
    },
    timeStramp: new Date().getTime(),
    isSingle: true
  }
}

// 心跳设置
const heartCheck = {
  heartbeatData: { type: 'PingReceipt' }, // 心跳包
  timeout: 30 * 60 * 1000, // 每段时间发送一次心跳包 这里设置为60s
  heartbeat: null, // 延时发送消息对象（启动心跳新建这个对象，收到消息后重置对象）
  start: function() {
    this.heartbeat = setInterval(() => {
      if (isConnect) {
        webSocketSend(this.heartbeatData)
      } else {
        this.clear()
      }
    }, this.timeout)
  },
  reset: function() {
    clearInterval(this.heartbeat)
    this.start()
  },
  clear: function() {
    clearInterval(this.heartbeat)
  }
}

// 清空所有事件
function resetOption() {
  subList = []
}

// 初始化websocket
function initWebSocket(symbol2, url2, options = {}) {
  socketOptions = { ...CreatSocketOption(), ...options }

  return new Promise((resolve, reject) => {
    if (!url2) {
      resolve(null)
    }
    // console.log("(webSocketMap[url]", webSocketMap);
    if (webSocketMap[url2]) {
      // console.log('symbol ----- inner ----- ', symbol2, '--- ', webSocket)
      resolve(webSocketMap[url2])
      return
    }
    successList[url2] = socketOptions.success
    isOnInit = true
    // console.log('symbol ----- out --- ', symbol2, url2)
    symbol = symbol2 ?? symbol
    url = url2
    const token = socketOptions.token || ''
    const baseUrl = token
      ? `${url2}?token=${token}&os=3&time=${socketOptions.timeStramp}`
      : `${url2}?time=${socketOptions.timeStramp}`
    // console.log("baseUrl", baseUrl);
    webSocket = new WebSocket(baseUrl) // 创建socket对象
    webSocketMap[url2] = webSocket
    webSocketMap[url2].binaryType = socketOptions.binaryType
    // 打开
    webSocketMap[url2].onopen = function() {
      webSocketOpen(webSocketMap[url2], url2)
    }
    // 收信
    webSocketMap[url2].onmessage = function(e) {
      webSocketOnMessage(e, url2)
    }
    // 关闭
    webSocketMap[url2].onclose = function(e) {
      webSocketOnClose(e, url2)
    }
    // 连接发生错误的回调方法
    webSocketMap[url2].onerror = function(e) {
      webSocketonError(e)
    }
    resolve(null)
  })
}

// 连接socket建立时触发
function webSocketOpen(data, curUrl) {
  // console.log('webSocketOpen', data)
  console.log('WebSocket连接成功')
  isOnInit = false
  isConnect = true
  heartCheck.start()
  // if (socketOptions.success && typeof socketOptions.success == "function") {
  for (const key in successList) {
    if (key == curUrl) {
      successList[key](data)
    }
  }
  // socketOptions.success(data);
  // }
}

// 客户端接收服务端数据时触发,e为接受的数据对象
function webSocketOnMessage(e) {
  // console.log('websocket信息:', symbol)
  // console.log(e, "e");
  let data = null
  if (e.data != '消息格式有误') {
    data = JSON.parse(e.data) // 根据自己的需要对接收到的数据进行格式化
    // console.log("subList", subList);
    // console.log("evt", evt);
    // console.log('OnMessage ---', subList, e.origin)
    if (subList.length > 0) {
      // console.log("subList", subList);
      subList.forEach((item) => {
        evt.emit(item, data, { origin: e.origin + '/', subName: item })
      })
    }
  }
}

// socket关闭时触发
function webSocketOnClose(e, curUrl) {
  heartCheck.clear()
  isConnect = false // 断开后修改标识
  console.log('webSocket已经关闭 (code：' + e.code + ')')
  // 避免页面切换关闭
  if (isOnInit) return
  const base_url = curUrl || url
  delete webSocketMap[base_url]
  // webSocketMap[url] = null;
  evt.emit('_close')
  // 被动断开，重新连接
  if (socketOptions.openAutoConnect) {
    if (socketOptions.maxConnectNum !== -1) {
      if (hasConnectNum < socketOptions.maxConnectNum) {
        if (![1000, 1005].includes(e.code)) {
          hasConnectNum += 1
          initWebSocket(symbol, base_url, socketOptions)
        }
      } else {
        // Message.error('超过重连次数，请联系管理员')
      }
    } else {
      initWebSocket(symbol, base_url, socketOptions)
    }
  }
}

// 连接发生错误的回调方法
function webSocketonError(e) {
  heartCheck.clear()
  isConnect = false // 断开后修改标识
  console.log('WebSocket连接发生错误:')
  // console.log(e)
}

// 发送数据
function webSocketSend(data, curUrl) {
  // console.log('webSocketSend --- curUrl', curUrl)
  // console.log('webSocketSend --- url', url)
  // console.log("webSocketMap", webSocketMap);
  console.log('webSocketMap', webSocketMap)
  const base_url = curUrl || url
  // console.log(data)
  // console.log("发送数据---", symbol, " --- ", webSocket && webSocket.readyState);
  if (webSocketMap[base_url] && webSocketMap[base_url].readyState === 1) {
    // 已连接状态
    webSocketMap[base_url].send(JSON.stringify(data)) // 在这里根据自己的需要转换数据格式
  }
}

// 在其他需要socket地方主动关闭socket
function closeWebSocket(name, url) {
  return new Promise((resolve, reject) => {
    if (webSocketMap[url]) {
      webSocketMap[url].close()
      // console.log(name, url);
      const index = subList.findIndex((v) => v == name)
      // console.log();
      if (index > 0) {
        subList.splice(index, 1)
      }
      delete webSocketMap[url]
      // console.log("closeWebSocket ---", subList, webSocketMap);
    }
    heartCheck.clear()
    isConnect = false
    resolve()
  })
}

/**
 * 订阅关闭事件
 */
function subCloseBack(name, callback) {
  evt.on('_close', () => {
    // console.log('测试关闭---- ', name)
    callback()
  })
}

/**
 * 订阅事件
 */
function subscribe(name, callback) {
  // console.log('evt.on---', name)
  // evt.on(name, callback);
  subList.push(name)
  subList.forEach((item) => {
    evt.on(item, callback)
  })
  // const has = subList.findIndex((v) => v == name)
  // console.log(subList)
  // if (has == -1) {
  //   subList.push(name)
  //   subList.forEach((item) => {
  //     evt.on(item, callback)
  //   })
  // }
}

/**
 * 取消订阅事件
 */
async function unsubscribe(name, curUrl) {
  // console.log('curUrl', curUrl)
  // console.log('url', url)
  await closeWebSocket(name, curUrl || url)
  // evt.removeAllListeners();
}

export default {
  initWebSocket,
  closeWebSocket,
  webSocketSend,
  subscribe,
  unsubscribe,
  subCloseBack,
  webSocket: webSocketMap[url],
  webSocketMap,
  resetOption
}
